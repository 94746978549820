const languageMapper = Object.freeze({
  en: 'en_GB',
  de: 'de_DE',
  pl: 'pl_PL',
});

const commercetoolsLanguageMapper = {
  en: 'en-GB',
  de: 'de-DE',
  pl: 'pl-PL',
};

const mapLanguage = (lang) => {
  if (!languageMapper[lang]) {
    console.error(`Language mapper is missing language ${lang}`);
  }

  //If language is not defined in languageMapper then select first locale
  return languageMapper[lang] || languageMapper[Object.keys(languageMapper)[0]];
};

module.exports = { languageMapper, commercetoolsLanguageMapper, mapLanguage };
