export const filterMapping = {
  'availableSizes': 'groesse',
  'attributes.BaseColor': '',
  'attributes.FormDescription': 'Form',
  'attributes.DesignerName': '',
  'attributes.IsXXL': 'onlyXXL',
  'attributes.ItemDesign': 'Design',
  'attributes.Material': 'Material',
  'attributes.IsSale': 'istSale',
};

export const sortMapping = {
  default: 'default',
  new: 'new',
  price_desc: 'pricehigh',
  price_asc: 'pricelow',
  sale: 'sale',
};
