import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { AlgoliaConfig } from '@frontastic-engbers/types/engbers-custom';
const Index = dynamic(() => import('react-instantsearch-core').then(module => module.Index));
export const useLocalizedIndex = (config: AlgoliaConfig) => {
  const router = useRouter();
  const locale = router?.locale || router?.defaultLocale;
  const indexes = {
    de: config.indexName + '_default'
  };
  return indexes[locale as 'de'];
};
const LocalizedIndex = ({
  children,
  config
}) => {
  const indexName = useLocalizedIndex(config);
  return <Index indexName={indexName} data-sentry-element="Index" data-sentry-component="LocalizedIndex" data-sentry-source-file="index.tsx">{children}</Index>;
};
export default LocalizedIndex;