import { IndexUiState } from 'instantsearch.js';
import { SearchQuery } from '@engbers/components/online-shops/product-list/types';
import { filterMapping, sortMapping } from '@frontastic-engbers/helpers/constants/algolia';
import { Route } from '@frontastic-engbers/types/instant-search/Route';

export class InstantSearchHelpers {
  private static readonly SEARCH_PARAMETERS = ['query', 'filter', 'page', 'sortby'];
  private static readonly SEARCH_PATH = '/search';

  public static indexUiStateToRoute(indexUiState: IndexUiState): Route {
    return {
      configure: indexUiState?.configure?.query ? { query: indexUiState.configure.query } : undefined,
      sortBy: indexUiState?.sortBy,
      refinementList: { ...indexUiState?.refinementList },
      page: !indexUiState?.page || isNaN(indexUiState.page) ? 1 : +indexUiState.page,
    } as Route;
  }

  public static routeToIndexUiState(route: Route): IndexUiState {
    return {
      configure: route.configure?.query ? { query: route.configure.query } : undefined,
      sortBy: route.sortBy,
      refinementList: route.refinementList,
      page: !route.page || route.page <= 1 ? undefined : route.page,
    } as IndexUiState;
  }

  public static routeToSearchQuery(
    route: Route,
    indexName?: string,
    defaultParameters?: Record<string, any>,
  ): SearchQuery {
    const searchQuery: SearchQuery = {
      query: route.configure?.query ? encodeURIComponent(route.configure.query as string) : undefined,
      filter: InstantSearchHelpers.refinementListToFilterString(route.refinementList ?? {}),
      page: !isNaN(route.page) && route.page > 1 ? route.page : undefined,
      sortby: InstantSearchHelpers.indexNameToSortById(route.sortBy, indexName?.replace('default', '') ?? ''),
      additionalParameters: {},
    };

    if (defaultParameters) {
      Object.keys(defaultParameters).forEach((parameter) => {
        if (!InstantSearchHelpers.SEARCH_PARAMETERS.includes(parameter)) {
          searchQuery.additionalParameters[parameter] = defaultParameters[parameter];
        }
      });
    }

    return searchQuery;
  }

  public static searchQueryToIndexUiState(searchQuery: SearchQuery, indexName?: string): IndexUiState {
    const sort = Object.keys(sortMapping).find((key) => sortMapping[key] === searchQuery.sortby);

    return {
      configure: searchQuery.query?.length > 0 ? { query: decodeURIComponent(searchQuery.query) } : undefined,
      sortBy: indexName && sort ? indexName.replace('default', sort) : indexName ?? sort,
      refinementList: InstantSearchHelpers.filterStringToRefinementList(searchQuery.filter ?? ''),
      page: !searchQuery.page || isNaN(searchQuery.page) ? 1 : +searchQuery.page,
    } as IndexUiState;
  }

  public static searchQueryToUrlPath(searchQuery: SearchQuery): string {
    const urlParts: string[] = [];

    if (searchQuery.query?.length > 0) {
      urlParts.push(searchQuery.query);
    }

    if (searchQuery.filter?.length > 0) {
      urlParts.push(encodeURIComponent(`_${searchQuery.filter}`));
    }

    if (searchQuery.page && searchQuery.page > 1) {
      urlParts.push(`${searchQuery.page}`);
    }

    return urlParts.length > 0 ? `/${urlParts.join('/')}` : '';
  }

  public static searchQueryToUrlParameters(searchQuery: SearchQuery): Record<string, string> {
    const urlParameters: Record<string, string> = searchQuery.sortby?.length > 0 ? { sortby: searchQuery.sortby } : {};
    if (searchQuery.additionalParameters) {
      Object.keys(searchQuery.additionalParameters).forEach((parameter) => {
        if (!InstantSearchHelpers.SEARCH_PARAMETERS.includes(parameter)) {
          urlParameters[parameter] = searchQuery.additionalParameters[parameter];
        }
      });
    }
    return urlParameters;
  }

  public static locationToSearchQuery(
    location: Location,
    defaultParameters?: Record<string, any>,
  ): SearchQuery | undefined {
    const basePath = InstantSearchHelpers.getBaseUrlByLocation(location, true);
    const pathParameters = location.pathname.slice(basePath.length + 1).split('/');
    const query = InstantSearchHelpers.isSearch(location.pathname) ? pathParameters.shift() : undefined;

    const searchQuery: SearchQuery = {
      query: query || defaultParameters?.['query'] || undefined,
      filter: defaultParameters?.['filter'] || undefined,
      page: defaultParameters?.['page'] || undefined,
      sortby: defaultParameters?.['sortby'] || undefined,
      additionalParameters: {},
    };

    while (pathParameters.length > 0) {
      const next = pathParameters.pop();
      if (next) {
        if (!isNaN(+next)) {
          searchQuery.page = +next;
        } else if (next.startsWith('_')) {
          searchQuery.filter = decodeURIComponent(next.slice(1));
        }
      }
    }

    if (defaultParameters) {
      Object.keys(defaultParameters).forEach((parameter) => {
        if (!InstantSearchHelpers.SEARCH_PARAMETERS.includes(parameter)) {
          searchQuery.additionalParameters[parameter] = defaultParameters[parameter];
        }
      });
    }

    return searchQuery;
  }

  public static getBaseUrlByLocation(location: Location, relativePath?: boolean): string {
    if (InstantSearchHelpers.isSearch(location.pathname)) {
      return `${!relativePath ? location.origin : ''}${InstantSearchHelpers.SEARCH_PATH}`;
    }

    const slug = location.pathname.replace(/(^\/|\/$)/g, '').split('/');
    return `${!relativePath ? location.origin : ''}${InstantSearchHelpers.getPathFromSlug(slug)}`;
  }

  public static getPathFromSlug(slug?: string[]): string {
    if (!slug) {
      return '/';
    }

    // Remove page parameter (if any)
    //   for now, we assume that we got a page parameter when the last slug entry is a number
    if (slug.length > 0 && !isNaN(Number(slug[slug.length - 1]))) {
      slug = slug.slice(0, slug.length - 1);
    }

    // Remove filter parameter (if any)
    //   for now, we assume that we got a filter parameter when the last slug entry starts with '_'
    if (slug.length > 0 && slug[slug.length - 1][0] === '_') {
      slug = slug.slice(0, slug.length - 1);
    }

    const path = slug.join('/');
    return `/${path !== 'index' ? path : ''}`;
  }

  public static refinementListToFilterString(refinementList: Record<string, string[]>): string | undefined {
    if (Object.keys(refinementList).length === 0 || Object.entries(filterMapping).length === 0) {
      return undefined;
    }

    let separator = '';
    let filter = '';

    Object.entries(filterMapping).forEach(([key, value]) => {
      let first = true;

      if (refinementList.hasOwnProperty(key)) {
        refinementList[key].forEach((filterValue: string) => {
          if (!first) {
            filter += '.';
          } else {
            filter += separator;
            separator = '';

            if (value !== '') {
              filter += value + '-';
            }
          }

          filter += filterValue;
          first = false;
        });
      }

      separator += '_';
    });

    return filter.length > 0 ? filter.replace(/%/g, '|perc|').replace(/\//g, '|') : undefined;
  }

  public static filterStringToRefinementList(filterString: string): Record<string, string[]> {
    const refinementList: Record<string, string[]> = {};
    const filters = filterString.split('_');

    Object.keys(filterMapping).forEach((key, index) => {
      if (filters[index]) {
        const parts = filters[index].split('-', 2);
        const values = parts[1] ?? parts[0];

        refinementList[key] = values
          .replace(/\|perc\|/g, '%')
          .replace(/\|/g, '/')
          .split('.');
      }
    });

    return refinementList;
  }

  public static indexNameToSortById(sortBy: any, indexPrefix: string): string | undefined {
    if (typeof sortBy !== 'string') {
      return undefined;
    }

    const sortingId = indexPrefix.length > 0 ? sortBy.match(`(?<=${indexPrefix}).*`)?.[0] ?? sortBy : sortBy;

    return sortMapping?.[sortingId] ?? sortingId;
  }

  private static isSearch(path: string): boolean {
    return path.startsWith(InstantSearchHelpers.SEARCH_PATH);
  }
}
