import { GetVouchersResponse } from "@frontastic-engbers/types/account/Account";
import { revalidateOptions } from "@frontastic-engbers/lib";
import { fetchApiHub } from "@frontastic-engbers/lib/lib/fetch-api-hub";
import useSWR from "swr";

export const useVouchers = () => {
    const result = useSWR<GetVouchersResponse>(
        "/action/account/getVouchers",
        fetchApiHub,
        revalidateOptions
    );

    if (!result.data) {
        return { loaded: false, vouchers: [] };
    }

    return { loaded: true, vouchers: result.data.vouchers };
};
