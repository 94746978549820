import { Component, ErrorInfo, ReactNode } from 'react';
interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
}
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };
  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    // TODO
    console.log({
      error,
      errorInfo
    });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div data-sentry-component="ErrorBoundary" data-sentry-source-file="error-boundary.tsx">
          <h2>Oops, there is an error!</h2>
          <p>Check the console for details.</p>
          <button type="button" onClick={() => this.setState({
          hasError: false
        })}>
            Try again?
          </button>
        </div>;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}
export default ErrorBoundary;