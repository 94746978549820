import { GetOrderHistoryResponse } from "@frontastic-engbers/types/account/Account";
import { revalidateOptions } from "@frontastic-engbers/lib";
import { fetchApiHub } from "@frontastic-engbers/lib/lib/fetch-api-hub";
import useSWR from "swr";

export const useOrderHistory = () => {
    const result = useSWR<GetOrderHistoryResponse>(
        "/action/account/getOrderHistory",
        fetchApiHub,
        revalidateOptions
    );

    if (!result.data) {
        return { loaded: false, orders: [] };
    }

    return { loaded: true, orders: result.data.orders };
};
