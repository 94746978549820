import { GetUsedCouponsResponse } from "@frontastic-engbers/types/account/Account";
import { revalidateOptions } from "@frontastic-engbers/lib";
import { fetchApiHub } from "@frontastic-engbers/lib/lib/fetch-api-hub";
import useSWR from "swr";

export const useUsedCoupons = () => {
    const result = useSWR<GetUsedCouponsResponse>(
        "/action/account/getUsedCoupons",
        fetchApiHub,
        revalidateOptions
    );

    if (!result.data) {
        return { loaded: false, usedCoupons: [] };
    }

    return { loaded: true, usedCoupons: result.data.usedCoupons };
};
