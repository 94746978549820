import * as React from 'react';
import * as CSS from 'csstype';
export function Grid({
  children,
  wrapperClassName,
  gridClassName
}: {
  children: React.ReactNode;
  gridClassName?: string;
  wrapperClassName?: string;
}) {
  const wrapperCss: CSS.Properties = {};
  const gridCss: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
    columnGap: '20px'
  };
  return <div className={`lg:px-auto px-2 ${wrapperClassName}`} style={wrapperCss} data-sentry-component="Grid" data-sentry-source-file="grid.tsx">
      <div className={gridClassName} style={gridCss}>
        {children}
      </div>
    </div>;
}