import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import singletonRouter from 'next/router';
import dynamic from 'next/dynamic';
import { UiState } from 'instantsearch.js';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import { InstantSearchHelpers } from '@frontastic-engbers/helpers/instantSearchHelpers';
import { AlgoliaConfig } from '@frontastic-engbers/types/engbers-custom';
import { useLocalizedIndex } from '../localized-index';
const InstantSearch = dynamic(() => import('react-instantsearch-core').then(module => module.InstantSearch));
interface IInstantSearchProvider {
  config?: AlgoliaConfig;
}
export const useInstantSearchClient = (config: AlgoliaConfig) => {
  return React.useMemo(() => algoliasearch(config.appId, config.appKey), []);
};
export const useInstantSearchRouting = (indexName: string) => {
  return {
    router: getInstantSearchRouter(indexName),
    stateMapping: getInstantSearchStateMapping(indexName)
  };
};
function getInstantSearchRouter(indexName: string) {
  return createInstantSearchRouterNext({
    singletonRouter,
    routerOptions: {
      cleanUrlOnDispose: false,
      createURL({
        qsModule,
        routeState,
        location
      }) {
        const searchQuery = InstantSearchHelpers.routeToSearchQuery(routeState?.[indexName] ?? {}, indexName, qsModule.parse(location.search.slice(1)));
        const baseUrl = InstantSearchHelpers.getBaseUrlByLocation(location);
        const urlPath = InstantSearchHelpers.searchQueryToUrlPath(searchQuery);
        const queryString = qsModule.stringify(InstantSearchHelpers.searchQueryToUrlParameters(searchQuery), {
          addQueryPrefix: true,
          arrayFormat: 'repeat'
        });
        return `${baseUrl}${urlPath}${queryString}`;
      },
      parseURL({
        qsModule,
        location
      }) {
        return {
          [indexName]: InstantSearchHelpers.searchQueryToIndexUiState(InstantSearchHelpers.locationToSearchQuery(location, qsModule.parse(location.search.slice(1))), indexName)
        };
      }
    }
  });
}
function getInstantSearchStateMapping(indexName: string) {
  return {
    // Note: use indexed structure to prevent type issues - "Property 'query' is incompatible with index signature."
    //  instantsearch expects both UiState and TRouteState to use an indexed structure
    stateToRoute(uiState: UiState) {
      return indexName.length > 0 ? {
        [indexName]: InstantSearchHelpers.indexUiStateToRoute(uiState[indexName] || {})
      } : undefined;
    },
    routeToState(routeState) {
      return indexName.length > 0 ? {
        [indexName]: InstantSearchHelpers.routeToIndexUiState(routeState[indexName] || {})
      } : undefined;
    }
  };
}
export const InstantSearchProvider: React.FC<IInstantSearchProvider> = ({
  children,
  config
}) => {
  if (!config) {
    return <>{children}</>;
  }
  const indexName = useLocalizedIndex(config);
  return <InstantSearch searchClient={useInstantSearchClient(config)} routing={useInstantSearchRouting(indexName)} future={{
    preserveSharedStateOnUnmount: false
  }} data-sentry-element="InstantSearch" data-sentry-component="InstantSearchProvider" data-sentry-source-file="index.tsx">
      {children}
    </InstantSearch>;
};
export default InstantSearch;